import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './Header';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import AccountPopup from './AccountPopup';
import ImageEditor from './ImageEditor';
import Meme from './Meme';
import { Provider } from 'react-redux';
import store from './store'; 
import Faq from './Faq';
import Success from './Success';
import { Helmet } from 'react-helmet';
import MemeFeed from './MemeFeed';
import Footer from './Footer';
import './App.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const [index, setIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true);
  const location = useLocation();

  const handleTabChange = (index) => {
    setIndex(index);
  };

  return (
    <div className="App">
      <Helmet>
        <title>AI Meme Generator</title>
        <meta name="description" content="Experience the best AI-powered meme generator. Generate hilarious memes super fast"/>
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="AI Meme Generator" />
        <meta property="og:url" content="https://memehaha.lol" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <ScrollToTop />

      {/* Conditionally render tabs only on the home page */}
      {location.pathname === '/' && (
        <Tabs selectedIndex={index} onSelect={handleTabChange}>
          <TabList className="full-width-tablist sticky-tablist">
            <Tab className="full-width-tab">Template</Tab>
            <Tab className="full-width-tab">Custom</Tab>
          </TabList>

          <TabPanel>
            <Meme setShowFooter={setShowFooter} />
          </TabPanel>
          <TabPanel>
            <ImageEditor setShowFooter={setShowFooter} />
          </TabPanel>
        </Tabs>
      )}

      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/success" element={<Success />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/latest-memes" element={<MemeFeed setShowFooter={setShowFooter} />} />
      </Routes>
      {showFooter && <Footer />}
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;
