import React, { useState, useEffect, useRef } from 'react';
import './Meme.css'; // Add your styles here
import { FaMagic } from 'react-icons/fa';
import axios from 'axios';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { ToastContainer, toast } from 'react-toastify';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import MemeFeedTemplate from './MemeFeedTemplate';
import { FaArrowUp } from 'react-icons/fa';
function Meme({setShowFooter}) {
  const setArrayToLocalStorage = (key, array) => {
    localStorage.setItem(key, JSON.stringify(array));
  };

  const getArrayFromLocalStorage = (key) => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  };

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [lines, setLines] = useState(2);
  const [id, setId] = useState(null);
  const [sourceUrl, setSourceUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [items, setItems] = useState(() => getArrayFromLocalStorage('context'));
  const [pngImageUrl, setPngImageUrl] = useState(selectedImage);
  const inputRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [visibleImages, setVisibleImages] = useState([]);
const [itemsToShow, setItemsToShow] = useState(20);
const [lastIndex, setLastIndex] = useState(0);
const [isButtonDisabled, setIsButtonDisabled] = useState(false);

useEffect(() => {
  setShowFooter(false);
  fetch('https://api.memegen.link/templates')
      .then(response => response.json())
      .then(data => {
          const filteredData = data.filter(item => item.id !== 'disastergirl' && item.id !== 'both'&& item.id!=="cheems" 
            && item.id!=="cryingfloor"&& item.id!=="dodgson"  && item.id!=="drunk" && item.id!=="dsm" &&  item.id!=="elmo"&& item.id!=="ermg"
            && item.id!=="fine"  && item.id!=="firsttry" && item.id!=="genie"&& item.id!=="ive"&&item.id!=="made"&&item.id!=="mmm"
            &&item.id!=="noah"&&item.id!=="perfection" && item.id!=="perfection");
          const imageData = filteredData.map((item, index) => ({
              url: item.blank,
              source: item.source,
              lines: item.lines,
              index: index,
              id:item.id
          }));
          setImages(imageData);
          setVisibleImages(imageData.slice(0, itemsToShow));
          setLastIndex(itemsToShow);
      })
      .catch(error => console.error('Error fetching images:', error));
}, []);



  const handleThumbnailClick = (url, source, lines, id) => {
    setSelectedImage(url);
    setPngImageUrl(url)
    setSourceUrl(source);
    setLines(lines);
    setId(id)
  };

  const handleScroll1 = (event) => {
    const { scrollLeft, clientWidth, scrollWidth } = event.target;
    if (scrollLeft + clientWidth >= scrollWidth - 20) { // Adjust the threshold as needed
        loadMoreItems();
    }
};

const loadMoreItems = () => {
    const newIndex = lastIndex + itemsToShow;
    if (newIndex <= images.length) {
        setVisibleImages(prev => [...prev, ...images.slice(lastIndex, newIndex)]);
        setLastIndex(newIndex);
    }
};

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  useEffect(() => {
    setArrayToLocalStorage('context', items);
  }, [items]);

  const addItems = (newItems) => {
    setItems((prevItems) => [...prevItems, ...newItems]);
  };

  const handleGenerateMeme = () => {
    setProgress(0);
    if (!sourceUrl || !prompt) {
      alert('Please provide both a meme description and template.');
      return;
    }

    const updateProgress = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 1;
        return newProgress >= 95 ? 95 : newProgress;
      });
    }, 50);

    setLoading(true);

    const formData = new FormData();
    formData.append('prompt', prompt);
    formData.append('sourceUrl', sourceUrl);
    formData.append('lines', lines);
    formData.append('id', id);
    formData.append('localContext', JSON.stringify(items)); 

    var url = "";
    if (getAuth().currentUser) {
      url = `https://easyedit.xyz:3000/generateTemplateMeme?uid=${getAuth().currentUser.uid}`;
    } else {
      url = `https://easyedit.xyz:3000/generateTemplateMeme?uid=default`;
    }

    axios.post(url, formData)
      .then(async (response) => {
        if (!getAuth().currentUser) {
          var userMessage = "";

          if (response.data.userMessage) {
            userMessage = response.data.userMessage;
          }

          var assistantMessage = "";

          if (response.data.assistantMessage) {
            assistantMessage = response.data.assistantMessage;
          }

          addItems([
            { role: "user", content: JSON.stringify(userMessage) },
            { role: "assistant", content: JSON.stringify(assistantMessage) },
          ]);
          setArrayToLocalStorage("context", items);
        }
        console.log(response.data.texts);
        var finalUrl = await buildUrlWithTexts(selectedImage, response.data.texts);
        console.log(finalUrl);
        setPngImageUrl(finalUrl);     
      })
      .catch(error => {
        clearInterval(updateProgress);
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
        setProgress(0);
      })
      .finally(() => {
       
        setLoading(false);
          handleScrollToTop()
        logEvent(getAnalytics(), 'meme_generated_template', {
          time: new Date().toISOString()
        });
      });
  };

  const buildUrlWithTexts = async (base, texts) => {
    let urlWithoutPng = base.endsWith('.png') ? base.slice(0, -4) : base;
    const textArray = Object.values(texts);
    const appendedText = textArray
      .map(text => text.replace(/\s/g, '_').replace(/\?/g, '~q'))
      .join('/');
    return `${urlWithoutPng}/${appendedText}.png`;
  };
  

  const uploadImageToStorage = async (dataUrl) => {
    const storage = getStorage();
    const storageRef = ref(storage, `templateMemes/${Date.now()}.png`);
  
    // Upload the image data URL to Firebase Storage
    await uploadString(storageRef, dataUrl, 'data_url');
  
    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);
  
    return downloadURL;
  };

  const handleDownload = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const watermarkText = "MemeHaHa.lol"; // Your watermark text
    
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 5000);
  
    img.crossOrigin = 'anonymous'; // Important if the image is hosted on a different domain
    img.src = pngImageUrl;
  
    img.onload = async () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
  
      // Set watermark styles
      const fontSize = 20; // Font size
      const padding = 0; // Padding from the bottom and left edges
      
      // Set font to measure text
      ctx.font = `${fontSize}px Arial`;
      
      // Measure text width and height
      const textWidth = ctx.measureText(watermarkText).width;
      const textHeight = fontSize; // Rough estimate of text height
  
      // Draw black background rectangle
      ctx.fillStyle = 'black';
      ctx.fillRect(padding - 3, canvas.height - textHeight - padding - 3, textWidth + 6, textHeight + 6);
  
      // Draw watermark text on top of the rectangle
      ctx.font = `${fontSize}px Arial`; // Set font for drawing text
      ctx.fillStyle = 'white'; // Watermark text color
      ctx.textAlign = 'left'; // Align watermark to the left
      ctx.textBaseline = 'bottom'; // Align watermark to the bottom
      ctx.fillText(watermarkText, padding, canvas.height - padding);
  
      canvas.toBlob(async (blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `meme-${Date.now()}.png`;
  
        link.click();
      });
  
      const dataUrl = canvas.toDataURL('image/png');
      await uploadImageToStorage(dataUrl);
      logEvent(getAnalytics(), 'meme_downloaded_template', {
        time: new Date().toISOString()
      });
    };
  
    img.onerror = (error) => {
      console.error('Image loading error:', error);
      toast.error('Failed to download the image.');
    };
  };
  
  
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 100); // Show button after scrolling 100px
  };

  return (
    <div>
    <div className="meme-container">
         {pngImageUrl && (
      <div className="png-image-container">
        <img src={pngImageUrl} alt="Generated Meme" className="png-image" />
      </div>
        )}
           {loading && (
  <div className="loading-bar-wrapper-meme">
    <div className="loading-bar-container-meme">
      <div className="loading-bar-meme" style={{ width: `${progress}%` }}></div>
    </div>
  </div>
)}
     {pngImageUrl && (
    <button
      className="download-button-template"
      onClick={handleDownload}
      disabled={isButtonDisabled}
    >
      {isButtonDisabled ? "Downloading..." : "Download"}
    </button>
  )}
<div style={{ fontWeight: 'bold', fontSize: '15px', color: '#fff', width:"100%", textAlign:"left", marginLeft:"30px", marginTop:"20px" }}>
   Choose Template:
  </div>
  <div className="thumbnail-container" onScroll={handleScroll1} style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
    {visibleImages.map((item, index) => (
        <img
            key={index}
            src={item.url}
            alt={`Thumbnail ${index}`}
            className="thumbnail"
            onClick={() => handleThumbnailClick(item.url, item.source, item.lines, item.id)}
            style={{ display: 'inline-block', marginRight: '10px' }} // Adjust spacing as needed
        />
    ))}
</div>


      <input
        className="prompt-input-bottom custom-input"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the meme you want to be generated"
        ref={inputRef}
      />

      <button
        className="edit-button"
        onClick={handleGenerateMeme}
        disabled={loading}
      >
        {loading ? 'Generating...' : 'Generate New Meme'}
        <FaMagic style={{ marginLeft: "7px" }} />
      </button>
   
  
    </div>
    { isVisible && (
      <button 
        onClick={scrollToTop} 
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          width:"40px",
          height:"40px",
          backgroundColor: '#000000',
          color: '#fff',
          border: 'none',
          borderRadius: '50%',
          padding: '10px',
          cursor: 'pointer',
          zIndex: '1000',
          opacity:"60%"
        }}
      >
        <FaArrowUp />
      </button>)}
    <MemeFeedTemplate  setShowFooter={setShowFooter}/>
      <ToastContainer />
    </div>
  );
}

export default Meme;